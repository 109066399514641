import { createReducer, on } from '@ngrx/store';
import { EquipmentActions } from './equipment.actions';
import { EquipmentDetails, Equipments } from '../../../shared/interfaces/equipment.interfaces';
import { LogItem } from '../../../shared/interfaces/log.interface';

export const equipmentFeatureKey = 'equipment';

export interface EquipmentState {
  isLoading: boolean;
  error: unknown;
  equipments: Equipments | null;
  equipmentDetails: EquipmentDetails | null;
  equipmentActivityLog: LogItem[] | [];
  logIsLoading: boolean;
  equipmentConfig: {};
  configIsLoading: boolean;
  foundEquipment: any;
  searchIsLoading: boolean;
}

export const initialState: EquipmentState = {
  isLoading: false,
  error: null,
  equipments: null,
  equipmentDetails: null,
  equipmentActivityLog: [],
  logIsLoading: false,
  equipmentConfig: {},
  configIsLoading: false,
  foundEquipment: null,
  searchIsLoading: false
};

export const equipmentReducer = createReducer(
  initialState,

  on(EquipmentActions.loadEquipments, state => { return { ...state, isLoading: true } }),
  on(EquipmentActions.loadEquipmentsSuccess, (state, action) => { return { ...state, isLoading: false, error: null, equipments: action.data } }),
  on(EquipmentActions.loadEquipmentsFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),

  on(EquipmentActions.loadEquipmentDetails, state => { return { ...state, isLoading: true } }),
  on(EquipmentActions.loadEquipmentDetailsSuccess, (state, action) => { return { ...state, isLoading: false, error: null, equipmentDetails: action.data } }),
  on(EquipmentActions.loadEquipmentDetailsFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),

  on(EquipmentActions.updateEquipmentDetails, state => { return { ...state, isLoading: true } }),
  on(EquipmentActions.updateEquipmentDetailsSuccess, state => { return { ...state, isLoading: false, error: null } }),
  on(EquipmentActions.updateEquipmentDetailsFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),

  on(EquipmentActions.loadEquipmentActivityLog, state => { return { ...state, logIsLoading: true } }),
  on(EquipmentActions.loadEquipmentActivityLogSuccess, (state, action) => { return { ...state, logIsLoading: false, error: null, equipmentActivityLog: action.data } }),
  on(EquipmentActions.loadEquipmentActivityLogFailure, (state, action) => { return { ...state, logIsLoading: false, error: action.error } }),

  on(EquipmentActions.loadEquipmentConfiguration, state => { return { ...state, configIsLoading: true } }),
  on(EquipmentActions.loadEquipmentConfigurationSuccess, (state, action) => { return { ...state, configIsLoading: false, error: null, equipmentConfig: action.data } }),
  on(EquipmentActions.loadEquipmentConfigurationFailure, (state, action) => { return { ...state, configIsLoading: false, error: action.error } }),

  on(EquipmentActions.requestNTConfiguration, state => { return { ...state, configIsLoading: true } }),

  on(EquipmentActions.unsetEquipmentDetails, state => { return { ...state, equipmentDetails: null, equipmentActivityLog: [], equipmentConfig: {} } }),

  on(EquipmentActions.searchEquipment, state => { return { ...state, isLoading: true } }),
  on(EquipmentActions.searchEquipmentSuccess, (state, action) => { return { ...state, isLoading: false, error: false, foundEquipment: action.data } }),
  on(EquipmentActions.searchEquipmentFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),

  on(EquipmentActions.unsetFoundEquipment, state => { return { ...state, foundEquipment: null } }),

  on(EquipmentActions.unsetEquipmentStore, () => initialState)
);
