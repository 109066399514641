import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ConfigResponse, EquipmentDetails, Equipments } from '../../../shared/interfaces/equipment.interfaces';
import { LogItem } from '../../../shared/interfaces/log.interface';

export const EquipmentActions = createActionGroup({
  source: 'Equipment',
  events: {
    'Load Equipments': props<{ payload: any }>(),
    'Load Equipments Success': props<{ data: Equipments }>(),
    'Load Equipments Failure': props<{ error: unknown }>(),

    'Unset Equipment Store': emptyProps(),

    'Load Equipment Details': props<{ id: number | string | null }>(),
    'Load Equipment Details Success': props<{ data: EquipmentDetails }>(),
    'Load Equipment Details Failure': props<{ error: unknown }>(),

    'Update Equipment Details': props<{ payload: any }>(),
    'Update Equipment Details Success': props<{ id: number | string }>(),
    'Update Equipment Details Failure': props<{ error: unknown }>(),

    'Update Equipment Dealer': props<{ payload: { equipments: number[], newDealer: number } }>(),
    'Update Equipment Dealer Success': props<{ data: any }>(),
    'Update Equipment Dealer Failure': props<{ error: unknown }>(),

    'Update Simcard': props<{ id: number | string | null, imsi: number | string | null, iccid: number | string | null, msisdn: number | string | null }>(),
    'Update Simcard Success': props<{ id: number | string | null }>(),
    'Update Simcard Failure': props<{ error: unknown }>(),

    'Load Equipment Activity Log': props<{ sn: number | string }>(),
    'Load Equipment Activity Log Success': props<{ data: LogItem[] | [] }>(),
    'Load Equipment Activity Log Failure': props<{ error: unknown }>(),

    'Load Equipment Configuration': props<{ id: number | string }>(),
    'Load Equipment Configuration Success': props<{ data: Record<string, string> }>(),
    'Load Equipment Configuration Failure': props<{ error: unknown }>(),

    'Request NT Configuration': props<{ sn: number | string, userID: number | undefined }>(),
    'Request NT Configuration Success': props<{ data: unknown }>(),
    'Request NT Configuration Failure': props<{ error: unknown }>(),

    'Remove Equipment From Dealer': props<{ payload: { id: number } }>(),
    'Remove Equipment From DealerSuccess': props<{ data: any }>(),
    'Remove Equipment From DealerFailure': props<{ error: unknown }>(),

    'Search Equipment': props<{ payload: { sn: string, accountId: string, firmware: string } }>(),
    // TODO: crear interface para foundEquipment
    'Search Equipment Success': props<{ data: any }>(),
    'Search Equipment Failure': props<{ error: unknown }>(),

    'Unset Found Equipment': emptyProps(),

    'Unset Equipment Details': emptyProps(),
  }
});
